<template>
  <!-- 降序popover -->
  <el-dialog
    :title="$t('ascending')"
    :visible.sync="$store.state.sort.ascendingOrderPopoverVisible"
    custom-class="descendingOrder-popover"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    center
  >
    <div class="as-list-wrap">
      <div class="as-scroll-wrap">
        <div
          v-for="item in list"
          :key="item.id"
          class="as-list-item"
          :class="item.opacity == 100 ? 'active' : ''"
          :style="{ opacity: item.opacity + '%' }"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        class="des-btn"
        :loading="loading"
        @click="confirmDescendingOrder"
        icon="el-icon-check"
      >
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import { postSort } from "@/api/newVersion/postContentDetails";
export default {
  data() {
    return {
      textarea: "",
      value: "",
      loading: false,
      currentId: 3,
      list: [
        {
          id: 5,
          value: 5,
          opacity: 30,
        },
        {
          id: 1,
          value: 20,
          opacity: 60,
        },
        {
          id: 2,
          value: 100,
          opacity: 100,
        },
        {
          id: 3,
          value: 500,
          opacity: 60,
        },
        {
          id: 4,
          value: 1000,
          opacity: 30,
        },
      ],
    };
  },

  props: {
    // setDeleteStatus
    reasonList: [],
  },
  mounted() {
    window.addEventListener(
      "mousewheel",
      this.debounce(this.handleScroll, 200),
      true
    ) ||
      window.addEventListener(
        "DOMMouseScroll",
        this.debounce(this.handleScroll, 200),
        false
      );
  },
  beforeDestroy() {
    window.removeEventListener(
      "mousewheel",
      this.debounce(this.handleScroll, 200),
      true
    );
    window.removeEventListener(
      "DOMMouseScroll",
      this.debounce(this.handleScroll, 200),
      false
    );
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function() {
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    handleScroll(e) {
      if (
        e.target.className &&
        e.target.className.indexOf("as-list-item") != "-1"
      ) {
        let direction = e.deltaY > 0 ? "down" : "up";
        if (this.currentId <= 1) {
          direction == "down" ? this.currentId++ : (this.currentId = 1);
        } else if (this.currentId >= this.list.length) {
          direction == "down"
            ? (this.currentId = this.list.length)
            : this.currentId--;
        } else {
          direction == "down" ? this.currentId++ : this.currentId--;
        }
        this.changeSel(this.currentId);
      }
    },
    changeSel(currentId) {
      let listNode = document.querySelector(".as-scroll-wrap");
      let childNode = listNode.childNodes;
      for (let i = 0; i < childNode.length; i++) {
        childNode[i].classList.remove("active");
      }
      childNode[currentId - 1].classList.add("active");
      let fg = -43;
      listNode.style.transform = `translateY(${fg * (currentId - 3)}px)`;
    },
    async confirmDescendingOrder() {
      this.loading = true;
      let result = await postSort({
        gigId: this.$store.state.sort.ascendingOrderPostId,
        moveIndex: this.list[this.currentId - 1].value,
        operation: 1,
      });
      if (result.code == 200) {
        this.$store.commit("setReloadPage", false);
        this.$nextTick((e) => {
          this.$store.commit("setAscendingOrderStatus", Date.now());
          this.$store.commit("setReloadPage", true);
        });
        this.clearDate();
      }
    },
    //取消操作
    dialogVisible() {
      this.clearDate();
      this.$store.commit("setAscendingOrderPopoverVisible", false);
    },
    beforeClose() {
      this.dialogVisible();
    },
    clearDate() {
      this.loading = false;
      this.currentId = 3;
      this.changeSel(3);
      this.$store.commit("setAscendingOrderPopoverVisible", false);
    },
  },
};
</script>

<style lang="stylus" scoped></style>
