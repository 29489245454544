var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("ascending"),
        visible: _vm.$store.state.sort.ascendingOrderPopoverVisible,
        "custom-class": "descendingOrder-popover",
        "before-close": _vm.beforeClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: ""
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.sort,
            "ascendingOrderPopoverVisible",
            $event
          )
        }
      }
    },
    [
      _c("div", { staticClass: "as-list-wrap" }, [
        _c(
          "div",
          { staticClass: "as-scroll-wrap" },
          _vm._l(_vm.list, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "as-list-item",
                class: item.opacity == 100 ? "active" : "",
                style: { opacity: item.opacity + "%" }
              },
              [_vm._v("\n        " + _vm._s(item.value) + "\n      ")]
            )
          }),
          0
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", {
            staticClass: "des-btn",
            attrs: {
              type: "primary",
              loading: _vm.loading,
              icon: "el-icon-check"
            },
            on: { click: _vm.confirmDescendingOrder }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }